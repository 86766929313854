import { css } from "@emotion/react";
import { breakpoints } from "@mytutor/shared-react-web-components/lib/theme";

import FAQ from "../FAQ";

const faqBackgroundStyle = css`
  width: 100%;
  height: 100%;
  background-image: url(landing/brand-shape5.svg), url(landing/brand-shape6.svg);
  background-position: bottom -400px left -420px, top -200px right -300px;
  background-size: 1100px, 800px;
  background-repeat: no-repeat;

  @media (max-width: ${breakpoints.desktop}px) {
    background-position: bottom -420px left -490px, top -200px right -300px;
    background-size: 1100px, 600px;
  }
  @media (max-width: ${breakpoints.phone}px) {
    background-position: bottom -200px left -260px, top -140px right -140px;
    background-size: 600px, 300px;
  }
`;

const faqContainerStyle = css`
  max-width: 980px;
  margin: 0 auto;
  padding: 80px 24px;
  @media (max-width: ${breakpoints.desktop}px) {
    padding: 40px 24px 80px;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    padding: 80px 24px;
  }
  @media (max-width: ${breakpoints.phone}px) {
    padding: 40px 24px;
  }
  h2 {
    font-family: "BureauGrotesk", sans-serif;
    font-size: 96px;
    font-weight: 400;
    line-height: 96px;
    margin: 0 0 64px;
    @media (max-width: ${breakpoints.desktop}px) {
      font-size: 80px;
      line-height: 80px;
      margin: 0 0 40px;
    }
    @media (max-width: ${breakpoints.tablet}px) {
      font-size: 44px;
      line-height: 44px;
      margin: 0 0 24px;
    }
  }
  div {
    max-width: 980px;
    padding: 0 24px;
    background-color: #fff;
    box-shadow: 0 6px 12px -6px rgb(24 19 87 / 12%),
      0 8px 24px -4px rgb(24 19 87 / 8%);
  }
`;

const faqContentStyle = css`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const FAQContent = () => (
  <ul css={faqContentStyle} className="faqs">
    <FAQ question="What makes a good tutor?" open={true}>
      <>
        <p>
          Subject knowledge is a must, but being a good tutor is really about
          explaining difficult concepts in a way that&apos;s clear, engaging and
          personalised for each student. You can have three degrees in Physics,
          but that doesn&apos;t mean you&apos;ll be good at explaining it at
          GCSE level! Tutors who can empathise with their students and help them
          build confidence help teens unleash their potential, and we see
          amazing results in just a few lessons.
        </p>
        <p>
          Our tutors are from top UK unis, and because they&apos;re just a few
          years older, they can explain things in a way that teens find
          relatable. We interview all of our tutors, and only the friendliest
          and most knowledgeable make it on to our platform. We&apos;re very
          picky about it - just 1 in 8 applicants make the cut.
        </p>
        <p>
          Pick from over 30 subjects at GCSE, A Level and beyond. Click below to
          find a tutor at the level you need:
        </p>
        <ul>
          <li>
            <a
              href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/11-plus/`}
            >
              11 Plus
            </a>
          </li>
          <li>
            <a
              href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/13-Plus/`}
            >
              13 Plus
            </a>
          </li>
          <li>
            <a href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/KS2/`}>
              KS2
            </a>
          </li>
          <li>
            <a href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/KS3/`}>
              KS3
            </a>
          </li>
          <li>
            <a href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/GCSE/`}>
              GCSE
            </a>
          </li>
          <li>
            <a
              href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/A-Level/`}
            >
              A-Level
            </a>
          </li>
          <li>
            <a
              href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/Scottish-Highers/`}
            >
              Scottish Highers
            </a>
          </li>
          <li>
            <a href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/IB/`}>
              International Baccalaureate (IB)
            </a>
          </li>
          <li>
            <a
              href={`${process.env.NEXT_PUBLIC_ZEUS_URL}/view-tutors/University/`}
            >
              University
            </a>
          </li>
        </ul>
      </>
    </FAQ>
    <FAQ question="Which tutor is right for you?">
      <p>
        Before you look for a tutor, it&apos;s helpful to have a really clear
        idea of exactly where your child needs help - whether with a specific
        English Literature text, one area of Maths or their exam technique - and
        filter your choices accordingly. If you&apos;re not sure where they need
        to focus, having a chat with them or their teacher can help you work out
        the best place to start. In a free meeting, you can then ask the tutor
        any questions you like and see how well they get on with your child
        before deciding to book.
      </p>
    </FAQ>
    <FAQ question="Why is online tutoring important?">
      <p>
        Online tutoring gives kids the chance to learn at their own pace and in
        a way that matches their learning style. Teens are often too shy to put
        their hand up in class - especially if they&apos;re struggling. The
        reassurance of one-to-one tutoring means they can ask all the questions
        they want, and go over topics as much as they need until they{" "}
        <em>get it</em>.
      </p>
    </FAQ>
    <FAQ question="What are the benefits of online tutoring?">
      <p>
        One-to-one tutoring lets kids unleash their potential. Worried about
        learning gaps? We&apos;ll fill them in. No tutors in your area?
        We&apos;ve got you covered. No academic confidence? No problem. Whatever
        your child needs help with, their tutor will guide them through tricky
        topics and boost their self-belief. With the personalised one-to-one
        support from their tutor, your child can get the grades they deserve.
      </p>
    </FAQ>
    <FAQ question="How much does a tutor cost?">
      <p>
        Our tutors set their own prices based on their experience and
        qualifications, starting from £25/hour at GCSE level. Most of our tutors
        charge between £25 and £39 an hour. You can see all the tutors who match
        your budget with the handy price filter on our Find a tutor page.
      </p>
    </FAQ>
    <FAQ question="How to find a tutor?">
      <dd>
        <p>
          Finding a tutor is easy. Whatever you need, you can search and filter
          by subject, level, price, experience and more.
        </p>
        <ol>
          <li>
            View our tutors, and narrow your search by subject, academic level
            or price. Or if you&apos;d like expert help, book a call with our
            free tutor matching service.
          </li>
          <li>
            Compare their profiles to learn about their background, experience
            and availability. Don&apos;t forget to take a peek at their reviews
            from parents and students!
          </li>
          <li>
            Message a tutor (or two, or three) and book your free meeting at a
            time that suits you.
          </li>
          <li>
            Book a lesson and pay as you go. There are no hidden costs and you
            can skip or cancel any time.
          </li>
        </ol>
      </dd>
    </FAQ>
    <FAQ question="How do online lessons work?">
      <p>
        We have our own online lesson space with video chat, messaging and an
        interactive whiteboard - this makes it easy for students and tutors to
        talk to each other, discuss tricky concepts and do practice questions
        together. With the live video chat, they can have a natural
        back-and-forth conversation - just like on FaceTime, Whatsapp and other
        apps teens use all the time.
      </p>
    </FAQ>
  </ul>
);

const FAQs = () => (
  <div css={faqBackgroundStyle}>
    <div css={faqContainerStyle}>
      <h2>Read our FAQs</h2>
      <div>
        <FAQContent />
      </div>
    </div>
  </div>
);

export default FAQs;
